<template>
  <v-card class="pa-4" :class="darkmode || 'grey lighten-5'" flat>
    <div class="mb-5 font-weight-bold" style="cursor: pointer; width: 100px">
      <v-btn
        @click="
          $router.push({
            name: 'eTest',
            query: {
              schoolYear: dataTable.header.school_year,
              grade: dataTable.header.grade,
              class: dataTable.header.class
            }
          })
        "
        depressed
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}</v-btn
      >
    </div>
    <AddEtest
      :open="openAddEvent"
      :info="dataTable"
      add
      @close="toggleAddEtest"
    />
    <HeaderInfo
      :disableBtn="table.loading"
      :info="dataTable"
      :buttonName="$t('app.add') + ' E-Test'"
      :showTotal="true"
      @actionButton="toggleAddEtest"
    />
    <Table
      :table="table"
      :dataTable="dataTable"
      class="mt-5"
      @onChangePaginate="paginateChange"
      @changeStatus="showModalConfirm"
      @reload="classDetail"
      @sort="
        item => {
          table.sort = item.sort;
          table.order = item.order;
          classDetail();
        }
      "
    />
    <ModalConfirm
      :title="titleModal"
      :close="() => (this.modalConfirm = false)"
      :loading="loadingConfirm"
      :isOpen="modalConfirm"
      :save="savePublish"
      >{{ contentModal }}</ModalConfirm
    >
    <v-dialog
      v-model="dialogPublish"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title primary-title>
          {{ $t("e_test.select_question_publish") }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-radio-group v-model="radioGroup">
            <v-radio :label="$t('e_test.the_main_question')" :value="1" />
            <v-radio :label="$t('e_test.backup_question')" :value="0" />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="loadingPublish"
            color="primary"
            outlined
            small
            @click="dialogPublish = false"
          >
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn
            :loading="loadingPublish"
            color="gradient-primary"
            dark
            depressed
            small
            @click="savePublish(true)"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  getClassDetail,
  unpublishQuestion,
  publishQuestion
} from "@/api/admin/academic/eTest";
import store from "@/store";

let typingTimer;
const doneTypingInterval = 750;

export default {
  components: {
    Table: () => import("./Table"),
    AddEtest: () => import("./dialog/AddEtest"),
    HeaderInfo: () => import("../components/HeaderInfo"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  created() {
    this.classDetail();
  },
  watch: {
    "table.search"() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.classDetail();
      }, doneTypingInterval);
    }
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    }
  },
  data() {
    return {
      radioGroup: 1,
      dialogPublish: false,
      loadingConfirm: false,
      loadingPublish: false,
      openAddEvent: false,
      modalConfirm: false,
      contentModal: "",
      titleModal: "",
      table: {
        limit: 10,
        page: 1,
        search: "",
        sort: [],
        loading: false,
        order: "",
        my_etest: this.$store.getters.g_role_type === "ADMIN" ? false : true
      },
      dataTable: {
        header: {},
        body: {
          data: []
        }
      },
      dataPublish: {}
    };
  },
  methods: {
    showModalConfirm(data) {
      this.dataPublish = { etest_class: data.id };
      if (data.status === "DRAFT") {
        this.contentModal = this.$i18n.t("e_test.msg_publish.unpublish");
        this.titleModal =
          this.$i18n.t("app.cancel") +
          " " +
          this.$i18n.t("e_test.publish_etest");
        this.modalConfirm = !this.modalConfirm;
      } else {
        this.dialogPublish = true;
      }
    },
    async savePublish(isPublish) {
      const msg =
        isPublish === true
          ? "e_test.msg_publish_etest"
          : "e_test.msg_unpublish_etest";
      this.loadingConfirm = true;
      this.loadingPublish = true;
      try {
        let response = {};
        if (isPublish === true) {
          // question_selected (1 == main question || 0 == backup question)
          response = await publishQuestion({
            ...this.dataPublish,
            question_selected: this.radioGroup
          });
          if (response.data.code) {
            const d = response.data.data;
            this.$socket.emit("serverNotif", {
              recipient: d.recipient,
              data: d
            });
          }
        } else {
          response = await unpublishQuestion(this.dataPublish);
        }
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t(msg));
          this.classDetail();
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        console.error("savePublish()\n", error);
      } finally {
        this.loadingConfirm = false;
        this.loadingPublish = false;
        this.modalConfirm = false;
        this.dialogPublish = false;
        // reset data
        this.dataPublish = {};
        this.radioGroup = 1;
      }
    },
    paginateChange(item) {
      this.table.limit = item.limit;
      this.table.page = item.page;
      this.classDetail();
    },
    toggleAddEtest() {
      if (this.openAddEvent) this.classDetail();
      this.openAddEvent = !this.openAddEvent;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async classDetail() {
      this.table.loading = true;
      this.$Progress.start();
      await getClassDetail({
        ...this.table,
        lesson: this.$route.params.lesson,
        class: this.$route.params.idClass
      })
        .then(res => {
          if (res.data.code === 1) {
            this.dataTable = res.data.data;
          } else {
            this.snackBar(false, res.data.message);
          }
        })
        .catch(error => console.error("classDetail()\n", error))
        .finally(() => {
          this.table.loading = false;
          this.$Progress.finish();
        });
    }
  }
};
</script>
